import { Navigate } from 'react-router-dom';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { UnforbiddenPage } from '../screens/UnforbiddenPage';

interface ProtectedPageProps{
    page: JSX.Element
}

export default function ProtectedPage({page}: ProtectedPageProps) {
    const {authenticated, currentUser} = useGeneralContext()
    
    if (!authenticated){
        return <Navigate to={"/login"} />
    } else if (authenticated && currentUser && !["GOtRJDxpAFgwwlPaZxSiZ6ieNS63", "oQhe17VABShk7U93hYRoYkoYKN22", "O5NlbAlpI8TQveXNCirt2wuWHJt1", "6QGOuCwgHbMKIJjcXDTFs9gjmRh2"].includes(currentUser?.uid)) {
        return <UnforbiddenPage />
    }
    return page
}
